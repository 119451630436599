import React, { useState, useEffect } from "react";

// Assuming `leaders` are fetched from an API
const Leaderboard = ({ category, fetchLeaders }) => {
  const [leaders, setLeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Simulate an API call to fetch leaders
    setIsLoading(true);
    fetchLeaders(category).then((data) => {
      setLeaders(data);
      setIsLoading(false);
    });
  }, [category, fetchLeaders]);
  
  if (isLoading) {
    return (
      <div className="spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (leaders.length === 0) {
    return <div>No leaders yet for this category.</div>; // Display message when no leaders
  }

  return (
    <div className="leaderboard">
      <h2>{category.categoryName} Leaderboard</h2>
      {isLoading ? (
        <div className="spinner">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="leader-list">
          {leaders.slice(0, 10).map((leader, index) => (
            <div key={index} className="leader-item">
              <span>
                {index + 1}. {leader.user.username}
              </span>
              <span>Slots Solved: {leader.totalWins}</span>
            </div>
          ))}
          {leaders.length > 10 && (
            <button
              className="expand-btn"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
