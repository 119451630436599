import "./layout.css";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// import { WorkerContext } from "../../context/worker/worker.context";
import { UserContext } from "../../context/user.context";
import { AuthContext } from "../../context/auth.context";
import { DisplayContext } from "../../context/display.context";

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const { worker } = useContext(WorkerContext);
  const [user] = useContext(UserContext);
  const [, setAuth] = useContext(AuthContext);
  // const [, setTokens] = useContext(AuthContext);
  const devicdeWidth = useContext(DisplayContext);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function logout() {
    // dispatch(user, 'clear');
    setAuth(undefined);
    //navigate("/");
    setLoggedOut(true);
    console.log("logged out");
  }

  // Redirect on logout
  useEffect(() => {
    if (!user && loggedOut) {
      navigate("/");
      setLoggedOut(false); // reset the loggedOut flag
    }
  }, [user, loggedOut, navigate]);

  return (
    <>
      {/* <header className="d-flex justify-content-between">
                <h1><Link to="/">
                    <img src="images/logo-white.png" 
                        height={devicdeWidth > 768 ? 64 : 32}
                    />
                </Link></h1>
                <nav>
                    <ul>
                        <li><Link to="/" className={pathname === '/' ? 'active' : ''}>Home</Link></li>
                        <li><Link to="/explore" className={pathname === '/explore' ? 'active' : ''}>Explore</Link></li>
                        {!user && <>
                            <li><Link to="/auth/signin" className={pathname === '/auth/signin' ? 'active' : ''}>Sign In</Link></li>
                            <li><Link to="/auth/signup" className={pathname === '/auth/signup' ? 'active' : ''}>Sign Up</Link></li>
                        </>}
                    </ul>
                </nav>
                {user && <div>
                    <Link to={'/account'} className="d-flex align-items-center btn text-light">
                        <img alt="" src="" className="border rounded-circle me-3" width={30} height={30} />
                        <span>{user.username}</span>
                    </Link>
                </div>}
            </header> */}

      <nav className="navbar sticky-top navbar-expand-lg">
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand">
            <img
              src="/images/logo-white.png"
              height={devicdeWidth > 768 ? 64 : 32}
            />
          </Link>
          <button
            className="navbar-toggler text-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon bg-light"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-center w-100">
              <li className={`nav-item`}>
                <Link
                  className={`nav-link ${`nav-item ${
                    pathname === "/" ? "active" : ""
                  }`}`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className={`nav-link  ${
                    pathname === "/explore" ? "active" : ""
                  }`}
                  to="/explore"
                >
                  Explore
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className={`nav-link  ${
                    pathname === "/leader" ? "active" : ""
                  }`}
                  to="/leader"
                >
                  LeaderBoard
                </Link>
              </li>
              {!user && (
                <>
                  <li className={`nav-item`}>
                    <Link
                      to="/auth/signin"
                      className={`nav-link  ${
                        pathname === "/auth/signin" ? "active" : ""
                      }`}
                    >
                      Sign In
                    </Link>
                  </li>
                  <li className={`nav-item`}>
                    <Link
                      to="/auth/signup"
                      className={`nav-link ${
                        pathname === "/auth/signup" ? "active" : ""
                      }`}
                    >
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </ul>
            {user && (
              <div className="dropdown">
                <Link
                  className="dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <img
                    alt=""
                    src="../images/m.png"
                    className="border rounded-circle me-3"
                    width={30}
                    height={30}
                  />
                  <span>{user.username}</span>
                </Link>
                <ul className="dropdown-menu ms-auto">
                  <li>
                    <Link className="dropdown-item text-dark" to="/account">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-dark"
                      to="/account/uploads"
                    >
                      My Uploads
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-dark"
                      to="/account/wins"
                    >
                      My Wins
                    </Link>
                  </li>
                  <li className={`nav-item`}>
                    <Link
                      className={`nav-link  ${
                        pathname === "/leader" ? "active" : ""
                      }`}
                      to="/leader"
                    >
                      LeaderBoard
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-dark"
                      to="/account/settings"
                    >
                      Settings
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item text-dark"
                      to="#"
                      onClick={logout}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>

      <main>
        <Outlet />
      </main>

      <footer className="">
        <div
          className={`${
            devicdeWidth > 480 ? "p-5" : "p-3"
          } d-md-flex align-items-center`}
        >
          <div className={devicdeWidth > 480 ? `w-50` : "mb-3 text-justified"}>
            <h3>About Play2o</h3>
            <p className="text-justify">
              Play2o is an online platform that provides earning opportunity to
              its users. Play2o applies patent pending, proof of play algorithm
              to reward users for completing the token generation tasks.
            </p>

            <nav className="social-bar">
              <ul className="nav-links">
                <img src="/images/logo-white.png" width={100} />
                &nbsp;&nbsp;
                <li>
                  <a href="https://twitter.com/Play_2_o">
                    <img src="/images/logo-x.png" width={26} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/play_2_o">
                    <img src="/images/logo-instagram.png" width={30} />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100094884782646">
                    <img src="/images/logo-facebook.png" width={30} />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@play2o">
                    <img src="/images/logo-tiktok.png" width={35} />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="d-flex ms-auto justify-content-between">
            <div className="me-4 d-flex flex-column link-group">
              <Link to={"/contactus"} className="d-block p-2 text-white">
                Contact Us
              </Link>
              <Link to={"/auth/signin"} className="d-block p-2 text-white">
                Sign In
              </Link>
              <Link to={"/auth/signup"} className="d-block p-2 text-white">
                Sign Up
              </Link>
              <Link to={"/termsofuse"} className="d-block p-2 text-white">
                Terms of Use
              </Link>
              <Link to={"/privacypolicy"} className="d-block p-2 text-white">
                Privacy Policy
              </Link>
            </div>

            {/* <div className=" link-group d-flex flex-column">
                            <Link to={'/contactus'} className="d-block p-2 text-white">Contact Us</Link>
                            <Link className="d-block p-2 text-white">Sign In</Link>
                            <Link className="d-block p-2 text-white">Sign Up</Link>
                            <Link to={'/termsofuse'} className="d-block p-2 text-white">Terms of Use</Link>
                            <Link to={'/privacypolicy'} className="d-block p-2 text-white">Privacy Policy</Link>
                        </div> */}
          </div>
        </div>

        <div className="text-center bg-white text-black p-3 mt-auto d-flex justify-content-between">
          <div>
            <strong>Patent pending</strong>
          </div>
          <div>Play2o &copy; {new Date().getFullYear()}</div>
          <div>All Rights Reserved</div>
        </div>
      </footer>
    </>
  );
}
