import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Layout from './components/layout/Layout';
import Explore from './pages/Explore/Explore';
import Games from './pages/games/Games';
import Play from './pages/play/Play';
import Authorization from './pages/Authorization/Authorization';
import Signin from './pages/Authorization/SignIn';
import Signup from './pages/Authorization/Signup';
import Account from './pages/account/Account';
import Profile from './pages/account/Profile';
import Uploads from './pages/account/uploads/index';
import Settings from './pages/account/Settings';
import useServiceWorker from './services/service-worker.service';
import AuthProvider from './context/auth.context';
import WorkerProvider from './context/worker/worker.context';
import Error from './components/errors/Error';
import Error404 from './components/errors/404';
import ForgotPassword from './pages/Authorization/ForgotPassword';
import DisplayProvider from './context/display.context';
import VerifyEmail from './pages/Authorization/VerifyEmail';
import UploadGame from './pages/UploadGame/UploadGame';
import WonGames from './pages/account/WonGames';
import PrivacyPolicy from './pages/privacy-policy';
import TermsOfUse from './pages/terms-of-use';
import Uploaded from './pages/account/uploads/Uploaded';
import ContactUs from './pages/contact-us';
import LeaderboardPage from './pages/leader/LeaderboardPage';

function App() {

  const serviceWorker = useServiceWorker();

  return (
    <BrowserRouter>
      <WorkerProvider>
        <AuthProvider>
          <DisplayProvider>
            <Routes>
              <Route path='/' element={<Layout />} errorElement={<Error404 />}>
                <Route index element={<Home />} />
                <Route path="/explore" element={<Explore />} />
                <Route path="/leader" element={<LeaderboardPage />} />
                <Route path="/explore/:category" element={<Explore />} />
                <Route path="/games" element={<Games />} />
                <Route path="/games/:category" element={<Games />} />
                <Route path="/play/:gameId" element={<Play />} />

                <Route path="/uploadgame" element={<UploadGame />} />

                <Route path="/auth" element={<Authorization />}>
                  <Route path='/auth/signin' element={<Signin />} />
                  <Route path='/auth/signup' element={<Signup />} />
                  <Route path='/auth/forgotpassword' element={<ForgotPassword />} />
                  <Route path='/auth/verify_email' element={<VerifyEmail />} />
                </Route>

                <Route path="/account" element={<Account />} >
                  <Route path='/account' element={<Profile />} />
                  <Route path='/account/uploads' element={<Uploads />} loader={async () => "restricted"} />
                  <Route path='/account/uploads/:id' element={<Uploaded />} loader={async () => "restricted"} />
                  <Route path='/account/settings' element={<Settings />} />
                  <Route path='/account/wins' element={<WonGames />} />
                </Route>

                <Route path='/contactus' element={<ContactUs />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/termsofuse' element={<TermsOfUse />} />

                <Route path='/error/:code' element={<Error />} />
                <Route path='*' element={<Error404 />} />

              </Route>
            </Routes>
          </DisplayProvider>
        </AuthProvider>
      </WorkerProvider>
    </BrowserRouter>
  );
}

export default App;
