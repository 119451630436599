import React from "react";

const CategoryList = ({ categories, onSelectCategory }) => {
  return (
    <div className="category-list">
      <h2>Categories</h2> {/* Add heading */}
      {categories.map((category, index) => (
        <div
          key={index}
          className="category-item"
          onClick={() => onSelectCategory(category)}
        >
          {category.categoryName}
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
