import React, { useState, useEffect, useCallback } from "react";
import CategoryList from "./CategoryList";
import Leaderboard from "./Leaderboard";
import useApi from "../../hooks/api.hook";
import "./leaderboard.css";

const LeaderboardPage = () => {
  const [categories, setCategories] = useState([]); // Initialize with an empty array
  const [selectedCategory, setSelectedCategory] = useState(null); // Start with no selected category
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const apiCLient = useApi();

  useEffect(() => {
    apiCLient.get("/parent_categories").then((response) => {
      console.log(response);
      if (!response.error) {
        setCategories(response.data);
        setSelectedCategory(response.data[0] || null);
        setIsLoading(false);
      }
    });
  }, []);

  const fetchLeaders = async (category) => {
    try {
      const response = await apiCLient.get(
        `/category_wins/${category.parentCategoryID}`
      );
      console.log(response);
      if (!response.error) {
        return response.data;
      }
      throw new Error("Error fetching leaders");
    } catch (error) {
      console.error("Failed to fetch leaders:", error.message);
      return []; // Return an empty array or handle the error appropriately
    }
  };

  if (isLoading) {
    return <div>Loading categories...</div>;
  }

  if (!categories.length) {
    return <div>No categories available</div>;
  }

  return (
    <div className="leaderboard-page">
      <CategoryList
        categories={categories}
        onSelectCategory={setSelectedCategory}
      />
      <Leaderboard category={selectedCategory} fetchLeaders={fetchLeaders} />
    </div>
  );
};

export default LeaderboardPage;
