import { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ApiClient from "../../services/api-client.service";
import { AuthContext } from "../../context/auth.context";
import { DisplayContext } from "../../context/display.context";

export default function Signin() {
//"start": "PORT=3011 && react-scripts start", windows use SET
    const [userData, setUserData] = useState({
        email: '',
        password: ''
    });
    const [validation, setValidation] = useState();
    const navigate = useNavigate();
    const apiClient = new ApiClient();
    const { state } = useLocation();
    const [, setTokens] = useContext(AuthContext);

    const deviceWidth = useContext(DisplayContext);
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get('return_url');

    function handleSubmit(evt) {

        evt.preventDefault();
        setValidation(undefined);

        apiClient.create('/auth/login', JSON.stringify(userData)).then(response => {
            console.log(response);
            if (!response.error) {
                setTokens(response.data);
                navigate(returnUrl ? returnUrl : '/explore')
            }
            else {
                setValidation({ error: response.message });
            }
        })
            .catch(e => {
                try{
                    let error = JSON.parse(e.message);
                    setValidation({ error: error.message });
                }
                catch(te){
                    setValidation({ error: e.message });
                }
            })

    }

    function handleField(evt) {
        setUserData({
            ...userData,
            [evt.target.name]: evt.target.value
        });
    }

    return (<>
        <section className="wrapper container h-100 d-flex justify-content-center">
            {/* <div className="w-50 p-5">
                Left
            </div> */}
            <form className={`p-5 ${deviceWidth < 480 ? '' : 'w-50'}`} onSubmit={handleSubmit}>
                <h3>Sign In</h3>
                <div className="d-flex justify-content-between">
                    <Link to="/auth/signup" className="btn text-nowrap text-white ps-0">Create New Account</Link>
                    <Link to="/auth/forgotpassword" className="btn text-nowrap text-white ps-0">Forgot Password?</Link>
                </div>
                <p>Enter your email address and password to sign in</p>
                {validation && <div className="p-3 mb-3 mt-3 bg-danger">{validation.error}</div>}

                <div className="form-group mb-3">
                    <label>Email</label>
                    <input type="email" name="email" value={userData.email} onChange={handleField} className="form-control" />
                </div>

                <div className="form-group mb-3">
                    <label>Password</label>
                    <input type="password" name="password" value={userData.password} onChange={handleField} className="form-control" />
                </div>


                <div className="form-group d-flex justify-conent-between">
                    <button className="btn btn-primary text-nowrap w-100">Sign In</button>
                </div>
            </form>
        </section>
    </>);

}